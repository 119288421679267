import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

import { ProfileService } from '@company/app/services/profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileGuard {
  constructor(
    private profileService: ProfileService,
    private router: Router
  ) {}

  redirectProfileIncompleteToOnboarding(): Observable<boolean | UrlTree> {
    return this.profileService.isProfileCompleted.pipe(
      switchMap((isProfileCompleted) => {
        if (isProfileCompleted) {
          return of(true);
        }

        return of(this.router.createUrlTree(['/', 'onboarding', 'personal']));
      })
    );
  }
}
