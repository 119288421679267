import { Injectable } from '@angular/core';
import { Message, Toast, ToastOptions, createToast } from 'vercel-toast';

import { environment } from '@company/environments/environment';

export type TypedToastOptions = Pick<
  ToastOptions,
  'action' | 'cancel' | 'timeout'
>;

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  show(message: Message, options?: ToastOptions): Toast {
    return createToast(message, options);
  }

  success(message: Message, options?: TypedToastOptions): Toast {
    return createToast(message, { type: 'success', timeout: 3000, ...options });
  }

  danger(message: Message, error?: Error, options?: TypedToastOptions): Toast {
    const expandedMessage =
      !environment.production && error
        ? `${message} DEBUG ERROR: ${error}`
        : message;

    return createToast(expandedMessage, {
      type: 'error',
      timeout: 3000,
      ...options
    });
  }
}
