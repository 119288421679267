import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User as FirebaseUser } from 'firebase/auth';
import { map, BehaviorSubject, switchMap, Observable, tap, of } from 'rxjs';

import { Profile } from '@company/app/models/profile';

import { AuthService } from '../auth/auth.service';
import { UtilityService } from '../utility/utility.service';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  userProfile$ = new BehaviorSubject<Profile | undefined>(undefined);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    private utilityService: UtilityService
  ) {}

  updateProfile(profile: Partial<Profile>) {
    return this.http.post<Profile>('company/update-user-profile/', profile);
  }

  fetchProfile(): Observable<Profile | undefined> {
    return this.authService.user
      .pipe(
        switchMap((user: FirebaseUser | null) => {
          if (user) {
            return this.http.get<Profile>('company/user-profile/').pipe(
              map((profile) => {
                const name =
                  `${profile.first_name} ${profile.last_name}`.trim() ||
                  user.displayName ||
                  user.email ||
                  '';

                return {
                  ...profile,
                  initials: this.utilityService.getInitials(name),
                  full_name: name,
                  profile_picture_url:
                    profile.profile_picture_url || user.photoURL || ''
                };
              })
            );
          }

          return of(undefined);
        })
      )
      .pipe(
        tap((profile) => {
          this.userProfile$.next(profile);
        })
      );
  }

  get isProfileCompleted() {
    return this.fetchProfile().pipe(
      map(
        (user) =>
          user && [user.first_name, user.last_name, user.email].every(Boolean)
      )
    );
  }
}
