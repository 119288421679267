import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, switchMap } from 'rxjs';

import { AuthService } from '@company/app/services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this.authService.authToken.pipe(
      switchMap((authToken) => {
        // If the user is logged in, add the auth token to the request.
        // But, we want to use agentToken for POST, PUT, PATCH, and DELETE requests if it exists.
        // Otherwise, we want to use authToken.
        const isPostPutPatchOrDelete = [
          'POST',
          'PUT',
          'PATCH',
          'DELETE'
        ].includes(request.method);

        if (isPostPutPatchOrDelete) {
          const agentToken = localStorage.getItem(
            this.authService.KEY_IMPERSONATOR_TOKEN
          );

          if (agentToken) {
            const authRequest = request.clone({
              setHeaders: {
                Authorization: agentToken
              }
            });

            return next.handle(authRequest);
          }
        }

        if (authToken) {
          const authRequest = request.clone({
            setHeaders: {
              Authorization: `Bearer ${authToken}`
            }
          });

          return next.handle(authRequest);
        }

        return next.handle(request);
      })
    );
  }
}
