<div
  class="impersonation-ribbon flex justify-content-center"
  *ngIf="profileService.userProfile$ | async as userProfile">
  <div
    class="impersonation-ribbon__content flex gap-3 align-items-center text-danger text-sm">
    <div class="impersonation-ribbon__content__text">
      <span class="impersonation-ribbon__content__text__label"
        >{{ 'impersonation.impersonating' | translate }}
      </span>
      <b class="impersonation-ribbon__content__text__value">{{
        userProfile.full_name | lowercase | titlecase
      }}</b>
    </div>
    <div class="impersonation-ribbon__content__actions">
      <p-button
        styleClass="p-button-sm p-button-text px-0"
        (click)="stopImpersonating()"
        icon="pi pi-times"
        label="{{ 'impersonation.stop' | translate }}">
      </p-button>
    </div>
  </div>
</div>
