<ng-container *ngIf="constants$ | async">
  <router-outlet> </router-outlet>
</ng-container>

<ngx-loading-bar
  diameter="50px"
  color="#643eff"
  [includeSpinner]="false"></ngx-loading-bar>

<app-impersonation-ribbon
  *ngIf="authService.isImpersonating$ | async"></app-impersonation-ribbon>
