import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';

import { ImpersonationRibbonComponent } from './impersonation-ribbon.component';

@NgModule({
  declarations: [ImpersonationRibbonComponent],
  imports: [CommonModule, ButtonModule, TranslateModule],
  exports: [ImpersonationRibbonComponent]
})
export class ImpersonationRibbonModule {}
