export const environment = {
  production: false,
  applicantAppUrl: 'https://jobs.dev.laenk.de/',
  firebase: {
    apiKey: 'AIzaSyDrdF5DvsrA3RRHMW2HaPNg9_DM_5BM7rI',
    authDomain: 'laenk-db9df.firebaseapp.com',
    projectId: 'laenk-db9df',
    storageBucket: 'laenk-db9df.appspot.com',
    messagingSenderId: '678115456098',
    appId: '1:678115456098:web:304f761d03ba932a764d3b',
    measurementId: 'G-PGMBFP714P'
  },
  app: {
    completeMagicLinkLoginPath: '/auth/complete-magic-link-login',
    defaultLanguage: 'en',
    logErrors: false,
    sentryEnvironment: 'development',
    pagination: {
      limitRows: 10
    },
    featureFlags: {
      duplicateJob: true
    },
    support: {
      email: 'info@laenk.de'
    },
    googleTagManagerId: 'GTM-5NXMXLQQ'
  },
  api: {
    baseUrl: 'https://pflegepiraten-dev.fly.dev/api/v1/'
  }
};
