import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { SpinnyComponent } from './spinny.component';

@NgModule({
  declarations: [SpinnyComponent],
  imports: [CommonModule, ProgressSpinnerModule],
  exports: [SpinnyComponent]
})
export class SpinnyModule {}
