import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { Profile } from '@company/app/models/profile';
import { AuthService } from '@company/app/services/auth/auth.service';
import { BroadcastService } from '@company/app/services/broadcast/broadcast.service';
import { ProfileService } from '@company/app/services/profile/profile.service';

@Component({
  selector: 'app-impersonation-ribbon',
  templateUrl: './impersonation-ribbon.component.html',
  styleUrls: ['./impersonation-ribbon.component.scss']
})
export class ImpersonationRibbonComponent implements OnDestroy {
  isImpersonating!: boolean;
  impersonatedProfile!: Profile;

  userSub?: Subscription;
  impersonationSub?: Subscription;

  constructor(
    public readonly authService: AuthService,
    public readonly profileService: ProfileService,
    private readonly broadcastService: BroadcastService
  ) {}

  stopImpersonating() {
    this.authService.logout().then(() => {
      this.broadcastService.emitLogout();
      window.location.reload();
    });
  }

  ngOnDestroy() {
    this.authService.isImpersonating = false;

    this.userSub?.unsubscribe();
    this.impersonationSub?.unsubscribe();
  }
}
