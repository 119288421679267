import { Injectable } from '@angular/core';
import { take } from 'rxjs';

import {
  loginMessage,
  logoutMessage
} from '@company/app/data/broadcast-message';

import { AuthService } from '../auth/auth.service';

@Injectable({
  providedIn: 'root'
})
export class BroadcastService {
  public readonly authenticationChannel: BroadcastChannel;

  constructor(private authService: AuthService) {
    this.authenticationChannel = new BroadcastChannel('authentication');
  }

  handleAuthenticationMessages() {
    this.authenticationChannel.onmessage = async (event) => {
      if (event.data === logoutMessage) {
        this.authService.user.pipe(take(1)).subscribe(async (user) => {
          if (user) {
            await this.authService.logout();
          }

          window.location.reload();
        });
      }

      if (event.data === loginMessage) {
        window.location.reload();
      }
    };
  }

  emitLogout() {
    this.authenticationChannel.postMessage(logoutMessage);
  }
}
