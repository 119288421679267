import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, map, switchMap, tap } from 'rxjs';

import { Company, CompanyWithAddress } from '@company/app/models/company';
import { DropdownOption } from '@company/app/models/dropdown-option';

import { ProfileService } from '../profile/profile.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  userCompanies = new BehaviorSubject<Company[]>([]);

  constructor(
    private readonly http: HttpClient,
    private readonly profileService: ProfileService
  ) {}

  fetchOrganization(id: string | number): Observable<Company> {
    return this.http.get<Company>(`company/${id}/`);
  }

  fetchUserOrganizations(): Observable<Company[]> {
    return this.http.get<Company[]>('company/user-organizations/').pipe(
      tap((companies) => {
        this.userCompanies.next(companies);
      })
    );
  }

  createOrganization(value: Partial<CompanyWithAddress>) {
    const user = this.profileService.userProfile$.value!;

    const params = {
      user_id: user.id
    };

    return this.http.post<Company>('company/', value, { params }).pipe(
      switchMap((company) => {
        return this.fetchUserOrganizations().pipe(map(() => company));
      })
    );
  }

  updateOrganization(id: string | number, value: Partial<Company>) {
    return this.http.patch<Company>(`company/${id}/`, value).pipe(
      switchMap((company) => {
        return this.fetchUserOrganizations().pipe(map(() => company));
      })
    );
  }

  createOrUpdateOrganization(value: Partial<CompanyWithAddress>) {
    if (value.external_id) {
      return this.updateOrganization(value.external_id, value);
    }

    return this.createOrganization(value);
  }

  getCompanyOptions(): Observable<DropdownOption<string | number>[]> {
    return this.userCompanies.pipe(
      map((companies) => {
        return companies
          .map((company) => ({
            label: company.name ?? '',
            value: company.external_id ?? ''
          }))
          .filter((company) => !!company.value);
      })
    );
  }
}
