import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { PhoneNumberFormat, PhoneNumberUtil } from 'google-libphonenumber';
import { FilterMetadata } from 'primeng/api';
import { Observable, map } from 'rxjs';

import { DropdownOption } from '@company/app/models/dropdown-option';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  constructor(private http: HttpClient) {}

  getInitials(name?: string) {
    if (!name) return 'L';

    const names = name.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
  }

  fetchCountries(): Observable<DropdownOption<string>[]> {
    return this.http.get<{ [key: string]: string }>('company/countries/').pipe(
      map((countries) =>
        Object.entries(countries).map(([key, value]) => ({
          label: value,
          value: key
        }))
      )
    );
  }

  formatPhoneNumber(
    phoneNumber: string,
    countryCode = 'DE',
    format: PhoneNumberFormat = PhoneNumberFormat.INTERNATIONAL
  ) {
    const instance = PhoneNumberUtil.getInstance();
    const number = instance.parse(phoneNumber, countryCode);
    return instance.format(number, format);
  }

  flattenTableFilters(
    filters: Record<string, FilterMetadata | FilterMetadata[] | undefined>
  ) {
    return Object.fromEntries(
      Object.entries(filters).map(([key, value]) => {
        if (Array.isArray(value)) {
          return [key, value.map((v) => v.value)];
        }
        return [key, value!.value];
      })
    );
  }

  transformFiltersToTableMode(
    filters: Record<string, string | number>
  ): Record<string, FilterMetadata | FilterMetadata[]> {
    return Object.fromEntries(
      Object.entries(filters).map(([key, value]) => {
        return [key, { value }];
      })
    );
  }
}
