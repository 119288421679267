import { HttpClient } from '@angular/common/http';
import { Injectable, NgZone } from '@angular/core';
import {
  Auth,
  UserCredential,
  idToken,
  isSignInWithEmailLink,
  signInWithCustomToken,
  signInWithEmailLink,
  signOut,
  user
} from '@angular/fire/auth';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  readonly KEY_EMAIL_FOR_SIGNIN = 'emailForSignIn';
  readonly KEY_IMPERSONATOR_TOKEN = 'impersonatorToken';

  isImpersonating$ = new BehaviorSubject<boolean>(false);

  constructor(
    public auth: Auth,
    public zone: NgZone,
    private http: HttpClient,
    private translateService: TranslateService
  ) {
    this.auth.languageCode = this.translateService.currentLang;
  }

  private get loginActionUrl() {
    return `${window.location.origin}/auth/complete-magic-link-login`;
  }

  private get signInEmail() {
    // check if email is available as a param on the URL
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');

    if (email) {
      return email;
    }

    return window.localStorage.getItem(this.KEY_EMAIL_FOR_SIGNIN);
  }

  requestMagicLink(email: string): Observable<void> {
    return this.http.post<void>('company/magic-link/', {
      email,
      action_url: this.loginActionUrl
    });
  }

  async completeMagicLinkLogin(): Promise<UserCredential> {
    const emailLink = window.location.href;

    if (!isSignInWithEmailLink(this.auth, emailLink)) {
      throw new Error(
        this.translateService.instant('services.auth.errorInvalidEmailLink')
      );
    }

    if (!this.signInEmail) {
      throw new Error(
        this.translateService.instant('services.auth.errorNoEmailFound')
      );
    }

    return await signInWithEmailLink(this.auth, this.signInEmail, emailLink);
  }

  get authToken() {
    return idToken(this.auth);
  }

  get user() {
    return user(this.auth);
  }

  get isImpersonating() {
    return window.localStorage.getItem(this.KEY_IMPERSONATOR_TOKEN) !== null;
  }

  set isImpersonating(value: string | boolean) {
    if (typeof value === 'string') {
      window.localStorage.setItem(this.KEY_IMPERSONATOR_TOKEN, value);
    }

    this.isImpersonating$.next(Boolean(value));
  }

  checkIfUserIsImpersonating() {
    const impersonatorToken = window.localStorage.getItem(
      this.KEY_IMPERSONATOR_TOKEN
    );

    if (impersonatorToken) {
      this.isImpersonating = impersonatorToken;
    } else {
      this.isImpersonating = false;
    }
  }

  async logout(): Promise<void> {
    this.isImpersonating = false;

    window.localStorage.clear();

    return signOut(this.auth);
  }

  async impersonate(
    impersonationToken: string,
    agentToken: string
  ): Promise<UserCredential> {
    const userCredential = await signInWithCustomToken(
      this.auth,
      impersonationToken
    )
      .then((credentials) => {
        this.isImpersonating = agentToken;

        return credentials;
      })
      .catch((error) => {
        throw error;
      });

    return userCredential;
  }
}
