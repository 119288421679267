import { isDevMode } from '@angular/core';
import {
  MissingTranslationHandler,
  MissingTranslationHandlerParams
} from '@ngx-translate/core';

export class CustomMissingTranslationHandler
  implements MissingTranslationHandler
{
  handle(params: MissingTranslationHandlerParams) {
    console.warn('Missing translation for', params.key);

    if (isDevMode()) {
      return `MISSING TRANSLATION: ${params.key}`;
    }

    return params.key;
  }
}
