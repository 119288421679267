import { Injectable } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, of, switchMap } from 'rxjs';

import { OrganizationService } from '@company/app/services/organization/organization.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationGuard {
  constructor(
    private orgService: OrganizationService,
    private router: Router
  ) {}

  redirectNoOrgToOnboarding(): Observable<boolean | UrlTree> {
    return this.orgService.fetchUserOrganizations().pipe(
      switchMap((orgs) => {
        if (orgs.length > 0) {
          return of(true);
        }

        return of(this.router.createUrlTree(['/', 'onboarding']));
      })
    );
  }
}
